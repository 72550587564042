<template>
  <div class="request">
    <!-- 头部导航栏 -->
    <div class="card-header">
      <i class="el-icon-edit"></i>
      <span> Review Buyer Waiting Requests</span>
    </div>
    <!-- 底部商品栏 -->
    <div class="requestList">
      <!-- 订单搜索 -->
      <div class="reqBox">
        <p>Order Number:</p>
        <div>
          <el-input v-model="input"
                    placeholder="Please input the content"></el-input>
          <el-button icon="el-icon-zoom-in"
                     @click="searchGood()"> Search</el-button>
        </div>
      </div>
      <!-- 商品栏 -->
      <div class="requestBox"
           v-for="(item, index) in reqList"
           :key='index'>
        <!-- 商品图片 -->
        <div class="reqImg">
          <img :src="item.product_image_url"
               alt="" />
        </div>
        <!-- 商品名称和时间 -->
        <div class="reqGoods">
          <div class="reqName">
            <span :class="item.site_class"></span>
            <span @click="freeGood(item)">{{item.product_title}}</span>
          </div>
          <div class="reqSell"><b>Seller User</b> : {{item.firstname}}  {{item.lastname}}</div>
          <div class="reqTime">{{item.create_date | formatDate}}</div>
        </div>
        <!-- 商品卖方状态等 -->
        <div class="reqCuntent">
          <div class="reqSell" style="position: relative;top: 15px"><b>Amazon Review Link</b> : <a style="color: #409EFF" :href="item.review_url" target="_blank">{{item.review_url}}</a></div>
          <div class="reqSell"><b>Order Number</b> : {{item.order_number}} </div>
<!--          <div class="reqSell"><b>Seller User</b> : {{item.firstname}}  {{item.lastname}}</div>-->
        </div>
        <!-- 删除或购买商品 -->
        <div class="reqBtn">
          <el-button type="primary"
                     @click="dialogFormVisible = true"
                     class="success"
                     icon="el-icon-success">I'm ordered</el-button>
          <el-dialog title="Order information"
                     :visible.sync="dialogFormVisible"
                     :append-to-body="true">
            <el-form :model="form">
              <el-form-item label="Trade name:"
                            label-width="150px">
                <span>{{item.product_title}}</span>
              </el-form-item>
<!--              <el-form-item label="PayPal account:"-->
<!--                            label-width="150px">-->
<!--                <span>{{item.paypal_account}}</span>-->
<!--              </el-form-item>-->
              <el-form-item label="Amazon Review Link:"
                            label-width="150px">
                <span>{{item.review_url}}</span>
              </el-form-item>
              <el-form-item label="Creation time:"
                            label-width="150px">
                <span>{{item.create_date | formatDate}}</span>
              </el-form-item>
              <el-form-item label="Refund screenshot:"
                            label-width="150px">
                <el-image style="width: 100px; height: 100px"
                          :src="item.refund_screen_shots"
                          fit="fill"
                          :preview-src-list="item.img"></el-image>
              </el-form-item>
            </el-form>
            <div slot="footer"
                 class="dialog-footer">
              <el-button @click="dialogFormVisible = false">cancle</el-button>
              <el-button type="primary"
                         @click="successTips(item)" :loading="$store.state.loadingBool">confirm</el-button>
            </div>
          </el-dialog>
          <el-button type="primary"
                     @click="chatClick(item, true)"
                     class="round"
                     icon="el-icon-chat-dot-round">
            Chat
            <!-- <span v-if="numberBool=item.numberBool"
                  class="numberChat">{{item.numberId}}</span> -->
                   <span v-if="item.numberId"
                    class="numberChat">{{item.numberId}}</span>
          </el-button>
          <el-link :href="item.amazon_url"
                   class="share"
                   target="_blank"
                   icon="el-icon-share"
                   :underline="false">Amazon URL</el-link>
        </div>
        <!-- 消息提醒 -->
        <!-- <span class="information">1</span> -->
      </div>
      <!-- 分页 -->
      <div class="block"
           v-if="reqListBool">
        <div class="blockDiv">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-size="currentSize"
                         :page-sizes="[10, 20, 50, 100]"
                         layout="prev,sizes, pager, next, jumper"
                         :total="currentTotal">
          </el-pagination>
        </div>
      </div>
      <!-- 聊天室弹出层 -->
        <el-dialog title="chat"
                   :modal-append-to-body="false"
                   :visible.sync="chatTableVisible"
                   :close-on-click-modal="false"
                   width="600px"
                   :before-close="closeChat">
          <!-- 聊天室 -->
          <div class="chatBox">
            <!-- 聊天内容 -->
            <div class="textBox"
                 ref="textBox">
              <!-- 卖方 -->
              <div :class="chatItem.role === 'RULE_SELLER' ? 'chatLeft' : 'chatRight'"
                   v-for="(chatItem, index) in chatList"
                   :key="index">
                <div :class="chatItem.role === 'RULE_SELLER' ? 'seller' : 'buyer'">{{chatItem.firstname}} {{chatItem.lastname}}</div>
                <div :class="chatItem.role === 'RULE_SELLER' ? 'sellerText' : 'buyerText'">
                  <p v-if="chatItem.chatBool === true">{{chatItem.content}}</p>
                  <img v-else
                       :src="chatItem.content"
                       alt=""
                       style="max-height: 400px; max-width: 400px">
                  <p class="time" style="color: white">{{chatItem.create_date | formatDate}}</p>
                </div>
              </div>
            </div>
            <div class="sendText">
              <textarea name=""
                        id=""
                        cols="60"
                        rows="8"
                        v-model="chatTest.chatInpout"></textarea>
              <!-- <i class="el-icon-picture-outline"></i> -->
              <el-upload class="upload-demo"
                         action="https://crediscounts.com/photograph/picture"
                         :headers="chatToken"
                         :on-success="handlePreview"
                         :on-remove="handleRemove"
                         :before-upload="beforeAvatarUpload"
                         list-type="picture"
                         ref='upload'>
                <i class="el-icon-picture-outline"></i>
                <div slot="tip"
                     class="el-upload__tip">Can only upload pictures, and no more than 3MB</div>
              </el-upload>
            </div>
            <div class="send">
              <el-button @click="closeChat" type="info">Close</el-button>
              <el-button type="primary"
                         @click="sendChat()" :loading="$store.state.loadingBool">Send</el-button>
            </div>
          </div>
        </el-dialog>
    </div>
  </div>
</template>

<script>
// 引入接口
import {
  freeGood, // 获取所有订单
  search, // 按条件查询所有订单
  finishedOrder, // 买家确认收款截图
  chatGet, // 用户获取聊天信息
  count, // 用户未读消息
  chat // 用户发送聊天消息
} from '../../api/buyerAfter'
import {
  getToken
} from '../../utils/token'
// 格式化时间
import { formatDate } from '../../utils/data.js'
// 排序
import { upSort } from '../../utils/jsonSort'
export default {
  data () {
    return {
      dom: 'nihao',
      input: '',
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: ['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'],
      // 分页
      currentPage: 1,
      currentSize: 10,
      currentTotal: 0,
      // 商品栏
      reqList: [],
      reqListBool: false,
      // 弹出层控制
      dialogFormVisible: false,
      // 商品表单
      form: {
        order: ''
      },
      // 聊天室弹出层控制
      chatTableVisible: false,
      // 聊天室上传图片列表
      chatToken: '',
      // 消息列表
      chatList: [],
      // 消息内容
      // chatInpout: '',
      numberBool: false,
      // 计时器
      timer: '',
      // 图片表单
      chatTest: {
        fileList: [],
        chatInpout: ''
      }
    }
  },
  filters: {
    // 时间格式化
    formatDate (time) {
      const date = new Date(time)
      // // (new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    }
  },
  created () {
    this.chatToken = { Authorization: getToken() }
    this.$store.commit('getFreestatus', '6')
    this.$store.commit('getCount')
    this.getfreeGood(this.currentPage, this.currentSize)
    this.$store.commit('getLoading', false)
  },
  methods: {
    // 获取全部
    getfreeGood (page, size) {
      this.currentSize = size
      // this.reqList = []
      // 获取所有订单
      freeGood({
        page: page,
        size: size,
        status: this.$store.state.freeStatus
      }).then(result => {
        if (result.data.code === 200) {
          const testList = []
          this.currentTotal = result.data.data.total
          if (result.data.data.rows.length > 0) {
            result.data.data.rows.map(v => {
              // 用户未读消息
              count({
                freeRequestId: v.id
              }).then(result => {
                if (result.data.code === 200) {
                  for (const key in v) {
                    if (result.data.data !== 0) {
                      v.numberId = result.data.data
                      v.numberBool = true
                    } else {
                      v.numberBool = false
                    }
                  }
                  v.img = []
                  v.img.push(v.refund_screen_shots)
                  testList.push(v)
                }
                // this.reqList = testList
                this.reqList = testList.sort(upSort('id'))
                // 是否显示分页
                if (this.reqList.length > 0) {
                  this.reqListBool = true
                } else {
                  this.reqListBool = false
                }
              }).catch(err => {
                return err
              })
            })
          } else {
            this.reqList = []
            // 是否显示分页
            if (this.reqList.length > 0) {
              this.reqListBool = true
            } else {
              this.reqListBool = false
            }
          }
        }
      }).catch(err => {
        return err
      })
    },
    // 搜索订单
    searchGood () {
      // 按条件查询所有订单
      search({
        orderNumber: this.input,
        page: '1',
        size: '1',
        status: this.$store.state.freeStatus
      }).then(result => {
        if (result.data.code === 200) {
          this.reqList = result.data.data.rows
          this.currentTotal = result.data.data.total
          // 是否显示分页
          if (this.reqList.length > 0) {
            this.reqListBool = true
            this.$message.success(result.data.message)
          } else {
            this.reqListBool = false
            this.$message('Order information not found')
            // setTimeout(() => {
            //   this.$router.go(0)
            // }, 500)
          }
        }
        // ('result ==>', result)
      }).catch(err => {
        return err
      })
    },
    // 聊天室弹出层控制
    closeChat () {
      this.chatTableVisible = false
      // this.getfreeGood(this.currentPage, this.currentSize)
    },
    // 聊天室列表
    chatClick (item, boolean) {
      if (boolean) {
        this.reqList.map(v => {
          if (item.id === v.id) {
            delete v.numberId
          }
        })
        this.chatTableVisible = true
      }
      localStorage.setItem('buyer-item', JSON.stringify(item))
      let chatNum = 1
      this.timer = setInterval(() => {
        if (chatNum === 0) {
          clearInterval(this.timer)
        } else {
          // ('chatnum ==>', chatNum)
          chatNum--
          // 用户获取聊天信息
          chatGet({
            freeRequestId: item.id
          }).then(result => {
            if (result.data.code === 200) {
              result.data.data.rows.map(v => {
                if (v.content.indexOf('photograph') < 0) {
                  v.chatBool = true
                } else {
                  v.chatBool = false
                }
              })
              this.chatList = result.data.data.rows
              this.$nextTick(() => {
                const div = document.getElementsByClassName('textBox')[0]
                div.scrollTop = div.scrollHeight
              })
            }
            // ('resultChat =>', result)
          }).catch(err => {
            return err
          })
        }
      }, 10)
    },
    // 图片删除
    handleRemove (file, fileList) {
      this.chatTest.fileList = []
      fileList.map(v => {
        this.chatTest.fileList.push(`https://crediscounts.com/photograph/images/${v.response.message}`)
      })
    },
    // 图片上传成功
    handlePreview (file, fileList) {
      this.chatTest.fileList.push(`https://crediscounts.com/photograph/images/${fileList.response.message}`)
    },
    // 图片上传之前
    beforeAvatarUpload (file) {
      // (this.chatTest.fileList)
      const srcSize = file.size < (3072 * 3072)
      const srcReg = /image/g
      const srcBool = srcReg.test(file.type)
      if (!srcBool) {
        this.$message.error('Please upload the address')
        return false
      } else {
        if (!srcSize) {
          this.$message.error('The image size is less than 3MB')
          return false
        } else if (this.chatTest.fileList.length > 2) {
          this.$message.error('Send up to three pictures at a time')
          return false
        }
      }
    },
    // 确认提示
    successTips (item) {
      this.$store.commit('getLoading', true)
      finishedOrder({
        id: item.id
      }).then(result => {
        if (result.data.code === 200) {
          this.$store.commit('getLoading', false)
          this.$message.success(result.data.message)
          this.getfreeGood(this.currentPage, this.currentSize)
          this.$store.commit('getCount')
          this.dialogFormVisible = false
        } else {
          this.$store.commit('getLoading', false)
        }
      }).catch(err => {
        return err
      })
    },
    // 分页
    handleSizeChange (val) {
      // (`每页 ${val} 条`)
      this.getfreeGood(this.currentPage, val)
    },
    handleCurrentChange (val) {
      this.getfreeGood(val, this.currentSize)
    },
    // 发送消息
    sendChat () {
      const item = JSON.parse(localStorage.getItem('buyer-item'))
      for (const key in this.chatTest) {
        if (key === 'chatInpout') {
          if (this.chatTest.chatInpout === '' && this.chatTest.fileList.length === 0) {
            this.$message.error('Cannot send empty message')
          } else {
            this.$store.commit('getLoading', true)
            this.sendChattest(item, this.chatTest.chatInpout)
          }
        } else if (key === 'fileList') {
          this.$store.commit('getLoading', true)
          this.chatTest.fileList.map(v => {
            this.sendChattest(item, v)
          })
        }
      }
    },
    // 发送消息
    sendChattest (item, v) {
      // 用户发送消息
      chat({
        addVO: {
          content: `${v}`,
          free_request_id: item.id
        }
      }).then(result => {
        // ('result ==>', result)
        if (result.data.code === 200) {
          this.$store.commit('getLoading', false)
          localStorage.removeItem('buyer-item')
          this.chatTest.chatInpout = ''
          this.chatClick(item, false)
          this.$refs.upload.clearFiles()
        }
      }).catch(err => {
        return err
      })
    },
    // 进入赠品页面
    freeGood (item) {
      this.$router.push({ path: '/F_freeDetails', query: { goodUrl: item.url, goodId: item.product_id } })
    }
  }
}
</script>

<style lang='less' scoped>
.request {
  // 头部导航栏
  .card-header {
    padding: 0 20px;
    margin-bottom: 0;
    background-color: #f0f3f5;
    border-bottom: 1px solid #c8ced3;
    span {
      margin-left: 10px;
      line-height: 45px;
    }
  }
  // 底部商品栏
  .requestList {
    background-color: white;
    padding: 20px;
    min-height: 150px;
    // 订单搜索
    .reqBox {
      font-size: 16px;
      font-weight: bolder;
      div {
        margin-top: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      /deep/ .el-input {
        width: 150px;
        margin: 0;
      }
      /deep/ .el-button {
        color: #fff;
        background-color: #20a8d8;
        border-color: #20a8d8;
        padding: 10px;
        margin-left: 5px;
        &:hover {
          background-color: #1b8eb7;
          border-color: #1985ac;
        }
      }
    }
    // 商品栏
    .requestBox {
      margin-top: 15px;
      width: 100%;
      box-sizing: border-box;
      padding: 10px 20px;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      // 商品图片
      .reqImg {
        img {
          max-height: 100px;
          max-width: 100px;
        }
      }
      // 商品名称和时间
      .reqGoods {
        width: 30%;
        // margin-right: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100px;
        font-size: 14px;
        .reqName {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span {
            line-height: 2;
            &:nth-child(2) {
              width: calc(100% - 24px);
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 10px;
              cursor: pointer;
              color: #20a8d8;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .reqTime {
          line-height: 2;
          text-align: left;
        }
        .reqSell {
          width: 95%;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
      }
      // 商品卖方状态等
      .reqCuntent {
        width: 30%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        min-height: 100px;
        font-size: 14px;
        //margin-right: 170px;
        .reqSell {
          width: 100%;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        div {
          line-height: 2;
        }
      }
      // 删除或购买商品
      .reqBtn {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        /deep/ .el-button {
          padding: 5px 10px;
          /deep/ i {
            font-size: 16px;
          }
        }
        /deep/ .el-button--primary.is-plain {
          color: white;
        }
        .success {
          background-color: #4dbd74;
          border-color: #4dbd74;
        }
        .error {
          margin-left: 10px;
          background-color: #f86c6b;
          border-color: #f86c6b;
        }
        .round {
          margin-left: 10px;
          background-color: #20a8d8;
          border-color: #20a8d8;
          position: relative;
          .numberChat {
            position: absolute;
            right: -10px;
            top: -10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #f86c6b;
            color: white;
            text-align: center;
            line-height: 20px;
          }
        }
        .share {
          display: block;
          margin-left: 10px;
          font-size: 14px;
          padding: 7px 10px;
          border-radius: 5px;
          background-color: #2f353a;
          border-color: #2f353a;
          color: white;
        }
      }
      // 消息提醒
      .information {
        position: absolute;
        right: -10px;
        top: -10px;
        // display: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: white;
        background-color: tomato;
        text-align: center;
        line-height: 20px;
        font-weight: bolder;
      }
    }
  }
}
</style>
